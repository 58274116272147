import React from "react"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


export default function SuccessMessage(props) {
  return (
    <div className="text-center mb-4">
      <h4 className="mb-4" css={css`max-width: 500px; margin-left: auto; margin-right: auto;`}>{props.children}</h4>
      <span><FontAwesomeIcon icon={faCheckCircle} className="mr-3" size="5x" css={css`color: #8198b1;`} /></span>
    </div>
  )
}