import React, { useState } from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import ContentContainer from "../../components/contentcontainer"
import SuccessMessage from "../../components/successmessage"
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import SEO from "../../components/seo"

const CallbackForm = props => {

  // VALIDIERUNG DER FORMFELDER
  // man kann mit noValidate in der Form verhindern, dass der Browser eine Standardvalidierung macht (die nicht schön aussieht)
  // dann müssen wir uns aber selbst um die Validierung kümmern (dafür ist die Funktion unten)
  // VORHER: bevor ich das eingebaut habe wurde bei Submit direkt die Funktion props.handleSendClick ausgeführt
  // das lokale handleSubmit wurde hier als Zwischenebene eingezogen
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget
    // zuerst wird mit checkValidity geprüft ob alle Checks okay sind (required Felder auch ausgefüllt sind)
    if (form.checkValidity() === false) {
      // fall nein, setze validated auf true und zeige damit die Validierungs-UI an (Felder grün und rot markiert)
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      // falls die Validierung okay war, führe die Funktion aus, die über die Callbacks übergeben wurde
      // leite hier das event durch, damit von außen die Inhalte der Form ausgelesen werden können
      props.handleSendClick(event)
    }
  }

  // die Funktion wird ausgeführt, wenn der zurück button gedrückt wird
  const handleBtnPrevClick = () => {
    // springe zurück zur contact-Seite
    navigate('/contact/')
  }


  return (
    // Das noValidate ist notwendig, um das Standard-Browser-Verhalten zur Formvalidierung zu unterbinden
    <Form className="contactUserFlowContainer" onSubmit={handleSubmit} noValidate validated={validated}>
      <Form.Row>
        <Col>
          <Form.Group controlId="callbackForm.firstname">
            <Form.Label>Vorname</Form.Label>
            <Form.Control type="text" name="firstname" placeholder="Vorname" required />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="callbackForm.lastname">
            <Form.Label>Nachname</Form.Label>
            <Form.Control type="text" name="lastname" placeholder="Nachname" required />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Group controlId="callbackForm.phone">
        <Form.Label>Telefonnummer</Form.Label>
        {/* pattern validiert die Telefonnummer; erlaubt sind Digits, Whitespace () und -; min 10 Stellen, max 17 */}
        <Form.Control type="tel" pattern='^\+?[\d ()-]{10,17}' name="phone" placeholder="+49 151 1234567" required />
      </Form.Group>
      <Form.Group controlId="callbackForm.hp" className="d-none">
        <Form.Control type="text" name="hp" />
      </Form.Group>
      <Row className="mt-4">
        <Col>
          <Button onClick={handleBtnPrevClick} variant="primary" block className="mb-5">Zurück</Button>
        </Col>
        <Col>
          <Button type="submit" variant="primary" block className="mb-5">
            {props.showSpinner && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />}
              Später zurückrufen
            </Button>
        </Col>
      </Row>
    </Form>
  )

}




export default function Callback() {

  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCallbackSite, setShowCallbackSite] = useState(true);
  const handleSendClick = (e) => {
    // die normale Weiterleitung verhindern
    e.preventDefault()
    //setze showSpinner auf True, damit der Spinner angezeigt wird
    setShowSpinner(true)

    // Definiere neues Objekt formData mit allen Daten des Formulars
    const formData = new FormData(e.target)
    // ergänze ein weiteres Feld in den formData (damit wir in der E-Mail später wissen, wo die Daten herkommen)
    formData.append("subject", "Rückruf")
    // Fülle ein formDataObj mit allen Einträgen aus dem Formular
    const formDataObj = Object.fromEntries(formData.entries())
    // DEBUG
    // console.log(formData)
    // console.log(formDataObj)
    // console.log(JSON.stringify(formDataObj))


    // verwende die API, um Daten per POST dorthin zu senden
    // Vorsicht: als Body darf dort weder formData stehen (ist leer)
    // noch formDataObj -> das ist ein Object, wir brauchen aber JSON-Daten
    // also das Objekt noch in JSON stringifien
    fetch(`${process.env.CONTACTMAIL_API_URL}`, {
      method: 'POST',
      body: JSON.stringify(formDataObj),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then((data) => {
        // Success!
        // console.log('Success:', data); // DEBUG
        // Wenn der Request erfolgreich war, dann blende die Kontaktseite aus
        setShowCallbackSite(false)
        // und blende die Erfolgsmeldung ein
        setShowSuccessMessage(true);
      })
      .catch((error) => {
        // Error!
        // console.error('Error:', error); // DEBUG
        navigate('/404/')
      });

  }


  return (
    <div>
      <Layout>
        <SEO
          title="Rückruf | Babyfotografin Köln"
          description="Wir rufen dich gern zurück, wenn du noch Fragen zu deinen Babyfotos hast. Oder du schreibst mir einfach und wir besprechen alles weitere."
        />
        <ContentContainer>
          {showCallbackSite &&
            <div>
              <h4 className="mb-5 text-center">Ich rufe dich gerne <br />schnellstmöglich zurück!</h4>
              <CallbackForm handleSendClick={handleSendClick} showSpinner={showSpinner}></CallbackForm>
            </div>
          }
          {showSuccessMessage && <SuccessMessage>Wir haben deine Anfrage erhalten und melden uns baldmöglichst bei dir</SuccessMessage>}
        </ContentContainer>
      </Layout>
    </div>
  )
}
